<template>
  <div class="private">
    <div class="private__slider swiper">
      <swiper ref="swiperRef" :options="swiperOption">
        <swiper-slide
          v-for="story in stories"
          :key="story.id"
          :data-id="story.id"
        >
          <!-- SLIDE 1 -->
          <div
            class="private__slide"
            :style="{
              background: getGradient(story),
            }"
          >
            <div class="private__header">
              <div class="private__logo">
                <img :src="getLogo(story)" alt="logo" />
              </div>
              <div class="private__title">
                {{ story.attributes.header }}
              </div>
            </div>
            <div v-if="getBigImage(story)" class="private__img">
              <img :src="getBigImage(story)" alt="" class="mobile" />
            </div>
            <p v-if="story.attributes.text" class="private__descr">
              <vue-markdown :source="story.attributes.text">
                {{ story.attributes.text }}</vue-markdown
              >
            </p>

            <!-- <div
              class="private__slide-after"
              :style="{ backgroundColor: story.attributes.bg_color_2 }"
            ></div> -->
          </div>
        </swiper-slide>
      </swiper>
      <div class="private__buttons">
        <button class="private__close-btn" @click.prevent="goBack"></button>
        <div class="private__arrows">
          <button
            @click.prevent="prevSlide"
            class="private__prev-btn private__button"
          ></button>
          <button
            @click.prevent="nextSlide"
            class="private__next-btn private__button"
          ></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAllStoriesByType } from "@/api/story";
import VueMarkdown from "vue-markdown";
export default {
  name: "PagePrivateLabel",
  components: {
    VueMarkdown,
  },
  data() {
    return {
      stories: null,
      swiperOption: {
        loop: true,
        slideTo: 1,
        slidesPerView: 1,
        responsive: true,
        observer: true,
        observeParents: true,
        spaceBetween: 30,
        parallax: true,
        loopedSlides: null,
        loopAdditionalSlides: 0,
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.swiperRef;
    },
    slides() {
      return this.$refs.swiperRef?.swiperInstance?.slides;
    },
  },
  created() {
    getAllStoriesByType("stm").then((stories) => {
      this.stories = stories;
      this.$nextTick(() => {
        this.swiper.swiperInstance.slides.forEach((e, i) => {
          if (parseInt(e.dataset.id) == this.$attrs.id) {
            this.swiper.swiperInstance.slideTo(i, 0, false);
          }
        });
      });
    });
  },

  methods: {
    prevSlide() {
      this.swiper?.swiperInstance.slidePrev();
    },
    nextSlide() {
      this.swiper?.swiperInstance.slideNext();
    },
    getLogo(item) {
      return this.$helpers.getAbsolutePath(
        item?.attributes?.logo?.data?.attributes?.url
      );
    },
    goBack() {
      this.$router.push({
        name: "Home",
        params: this.$route.params,
        query: this.$route.query,
      });
    },
    getBigImage(item) {
      const bigImage = item?.attributes?.image?.data?.attributes?.url;
      if (bigImage) {
        return this.$helpers.getAbsolutePath(bigImage);
      }
    },
    getSmallImage(item) {
      const smallImage =
        item?.attributes?.image?.data?.attributes?.formats?.thumbnail?.url;
      if (smallImage) {
        return this.$helpers.getAbsolutePath(smallImage);
      }
    },
    getGradient(item) {
      return (
        "linear-gradient(108deg, " +
        item.attributes.bg_color_1 +
        " 60%, " +
        item.attributes.bg_color_2 +
        " 5%)"
      );
      // return item.attributes.bg_color_2;
    },
  },
};
</script>

<style scoped>
.swiper-container * {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.swiper-slide {
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
}
</style>
